const blacklist = [
  'ahole',
  'anal',
  'analprobe',
  'anilingus',
  'anus',
  'ass',
  'assbang',
  'assbanged',
  'assbangs',
  'assfuck',
  'assfucker',
  'assh0le',
  'asshat',
  'assho1e',
  'assholes',
  'assmaster',
  'assmunch',
  'asswipe',
  'asswipes',
  'b1tch',
  'ballsack',
  'bastard',
  'bastards',
  'beotch',
  'biatch',
  'bigtits',
  'bimbo',
  'bitch',
  'bitched',
  'bitches',
  'bitchy',
  'blowjob',
  'blowjobs',
  'bollock',
  'bollocks',
  'bollok',
  'boobies',
  'boobs',
  'bukkake',
  'bullshit',
  'bullshits',
  'bullshitted',
  'butt',
  'buttfuck',
  'buttfucker',
  'buttfucker',
  'buttplug',
  'c-0-c-k',
  'c-o-c-k',
  'c-u-n-t',
  'c.0.c.k',
  'c.o.c.k.',
  'c.u.n.t',
  'c0ck',
  'caca',
  'cahone',
  'clitoris',
  'clitorus',
  'cocain',
  'cocaine',
  'cock',
  'cock sucker',
  'cockblock',
  'cockholster',
  'cockknocker',
  'cocks',
  'cocksmoker',
  'cocksucker',
  'coital',
  'corksucker',
  'crack',
  'crackwhore',
  'crap',
  'crappy',
  'cum',
  'cummin',
  'cumming',
  'cumshot',
  'cumshots',
  'cumslut',
  'cumstain',
  'cunilingus',
  'cunnilingus',
  'cunt',
  'cuntface',
  'cunthunter',
  'cuntlick',
  'cuntlicker',
  'cunts',
  'd0ng',
  'd0uch3',
  'd0uche',
  'd1ck',
  'd1ld0',
  'd1ldo',
  'dammit',
  'damn',
  'damned',
  'damnit',
  'dawgie-style',
  'dick',
  'dick-ish',
  'dickbag',
  'dickdipper',
  'dickface',
  'dickflipper',
  'dickhead',
  'dickheads',
  'dickish',
  'dickripper',
  'dicksipper',
  'dickweed',
  'dickwhipper',
  'dickzipper',
  'dildo',
  'dildos',
  'dipship',
  'doggie-style',
  'doggy-style',
  'douch3',
  'douche',
  'douchebag',
  'douchebags',
  'douchey',
  'dumbass',
  'dumbasses',
  'ejaculate',
  'erection',
  'erotic',
  'f-u-c-k',
  'f.u.c.k',
  'fack',
  'faggot',
  'fagot',
  'fart',
  'fartknocker',
  'fellate',
  'fellatio',
  'fisted',
  'fisting',
  'fisty',
  'foreskin',
  'fuck',
  'fuck-tard',
  'fuckass',
  'fucked',
  'fucked',
  'fucker',
  'fuckface',
  'fuckin',
  'fucking',
  'fucknugget',
  'fucknut',
  'fuckoff',
  'fucks',
  'fucktard',
  'fuckup',
  'fuckwad',
  'fuckwit',
  'fudgepacker',
  'fuk',
  'fvck',
  'fxck',
  'g-spot',
  'gay',
  'gays',
  'ghay',
  'ghey',
  'gigolo',
  'gspot',
  'gtfo',
  'h0m0',
  'h0mo',
  'handjob',
  'hard on',
  'heroin',
  'homo',
  'hooker',
  'hooter',
  'hooters',
  'horny',
  'hymen',
  'incest',
  'j3rk0ff',
  'jackass',
  'jackhole',
  'jackoff',
  'jerk',
  'jerk0ff',
  'jerked',
  'jerkoff',
  'jizzed',
  'lesbians',
  'lezbian',
  'lezbians',
  'm-fucking',
  'masturbate',
  'masturbating',
  'masturbation',
  'motherfucka',
  'motherfucker',
  'motherfucking',
  'mtherfucker',
  'mthrfucker',
  'mthrfucking',
  'muthafuckaz',
  'muthafucker',
  'mutherfucker',
  'mutherfucking',
  'muthrfucking',
  'nazism',
  'negro',
  'nigga',
  'niggah',
  'niggas',
  'niggaz',
  'nigger',
  'nigger',
  'niggers',
  'niggle',
  'nympho',
  'orgasm',
  'orgasmic',
  'orgies',
  'orgy',
  'p.u.s.s.y.',
  'pedophile',
  'pedophilia',
  'pedophiliac',
  'penetrate',
  'penetration',
  'penis',
  'perversion',
  'porn',
  'porno',
  'pornography',
  'prostitute',
  'pube',
  'pubic',
  'pubis',
  'pussies',
  'pussy',
  'pussypounder',
  'queer',
  'queers',
  's-h-1-t',
  's-h-i-t',
  's.h.i.t.',
  'semen',
  'sex',
  'sexual',
  'sh1t',
  'shit',
  'shitface',
  'shithead',
  'shithole',
  'sperm',
  'sucking',
  'sumofabiatch',
  'titfuck',
  'tittiefucker',
  'titties',
  'tittyfuck',
  'tittyfucker',
  'vagina',
  'viagra',
  'virgin',
  'wh0re',
  'wh0reface',
  'whore',
  'whoreface',
  'whorehopper',
  'whorehouse',
  'whores',
  'x-rated',
  'zoophile',
  'autoaccidentteam',
  'leadprosper',
  'i love you',
  'iPhone 11',
  'iPhone 12',
  'iPhone x',
  'i dont need a lawyer',
  'fortnite',
  'gay',
  'china',
  'chinese',
  'hi hi',
  'love you',
  'assault',
  'attack',
  'hit and run',
  'chase',
  'mommy',
  'daddy',
  'mama',
  'rasta',
  'hoe',
  'I have a lawyer',
  "don't need a lawyer",
  "giftcard",
  "gift card",
  "gift",
  "card",
  "sweepstakes",
  "sweep stake",
  "sweep stakes",
  "swepstakes",
  "sweepstkes",
  "money",
  "winning",
  "free"
]

export const PATTERN_BLACK_LIST = new RegExp(`(^|\\W)(${blacklist.join('|')})($|\\W)`, 'gmi')
